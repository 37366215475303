@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, .App {
    /*height: 100%;*/
}
.html, body {
    background-color: #bdd4e7;
    background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
    background-attachment: fixed;
    --skew: 5deg;
}
.portfolio-container {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    border-radius: 5px;
    /*background-color: rgba(255, 255, 255, .25);*/
    background-color: white;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    /*background-image: linear-gradient(45deg, #6303B1, #ff0099);*/
    -webkit-transform: skewY(5deg);
            transform: skewY(5deg);
    width: 100%;
    /*background-color: white;*/
    /*background-image: linear-gradient(315deg, #44b09e 0%, #e0d2c7 74%);*/
    padding-inline: 4rem;

    /*margin-inline: 2rem;*/
}

.contact-container {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    border-radius: 5px;
    /*background-color: rgba(255, 255, 255, .25);*/
    background-color: white;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    /*background-image: linear-gradient(45deg, #6303B1, #ff0099);*/
    -webkit-transform: skewY(5deg);
            transform: skewY(5deg);
    width: 100%;
    /*background-color: white;*/
    /*background-image: linear-gradient(315deg, #44b09e 0%, #e0d2c7 74%);*/
    padding-inline: 4rem;

    /*margin-inline: 2rem;*/
}
.inner-container {
    z-index: 2;
    /*transform: skewY(-5deg);*/
}
.heading {
    font-family: 'Roboto', sans-serif;
    font-size: xx-large;
    cursor: pointer;
    /*color: white*/
}

/*.portfolio-project {*/
/*    !*position: relative;*!*/
/*}*/
/*.portfolio-project:after {*/
/*    position: absolute;*/
/*    z-index: -1;*/
/*    content: "";*/
/*    width: inherit;*/
/*    height: inherit;*/
/*    background: rgba(0,0,0, .45);*/
/*    transform: rotate(1.5deg) translateX(10px) translateY(15px) skewX(4deg) skewY(-4deg);*/
/*}*/

.resume-container {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    border-radius: 5px;
    background-color: white;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
    z-index: -1;
    -webkit-transform: skewY(var(--skew));
            transform: skewY(var(--skew));
    width: 100%;
    padding-inline: 4rem;
}
.resume-inner-container {
        /*transform: translateY(calc(var(--skew) / 6));*/
    -webkit-transform: skewY(-5deg);
            transform: skewY(-5deg);
    padding: 6rem;
}
.education-inner-container {
    /*transform: translateY(calc(var(--skew) / 6));*/
    -webkit-transform: skewY(-5deg);
            transform: skewY(-5deg);
    padding: 1rem;
    margin-bottom: 1rem;
}


@media only screen and (max-width: 600px) {
    .heading {
        text-align: center;
    }
    .portfolio-container {
        padding-inline: 1rem;
        -webkit-transform: skewY(0deg);
                transform: skewY(0deg);
    }
    .contact-container {
        -webkit-transform: skewY(0deg);
                transform: skewY(0deg);
        padding-inline: 2rem;
    }
    .education-inner-container {
        -webkit-transform: skewY(-5deg);
                transform: skewY(-5deg);
        padding: .2rem;
        margin-bottom: 1rem;
    }
    .inner-container {
    }
    .resume-inner-container {
        /*transform: translateY(calc(var(--skew) / 6));*/
        padding: 1rem;
    }
    .resume-container {
        padding: 1rem;
    }
}
